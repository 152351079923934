export default {
  data () {
    return {
      observer: null,
      isIntersecting: false
    }
  },
  methods: {
    createObserver () {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          this.isIntersecting = entry.isIntersecting
        })
      }, {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      })
    },
    observeElement (element) {
      this.observer.observe(element)
    },
    unobserveElement (element) {
      this.observer.unobserve(element)
    }
  },
  mounted () {
    this.createObserver()
  },
  beforeDestroy () {
    this.observer.disconnect()
  }
}
