<template>
  <div class="product-details" v-if="productData">
    <div class="container" v-if="showHintUnavailableProduct">
      <HintBar class="hint-bar--orange">
        <span>
          Item is not currently available; please <router-link to="/customer-support" >Contact Us</router-link> to resolve
        </span>
      </HintBar>
    </div>
    <div class="container" v-if="productData.promoBanner">
      <HintBar class="hint-bar--gray" :hintData="productData.promoBanner"></HintBar>
    </div>
    <section class="product-details__header container">
      <div class="product-details__header-left">
        <div
          v-if="visibleCategories && visibleCategories.length"
          class="product-details__categories-wrap"
        >
          <ul
            class="product-details__categories"
            :class="{active: isCategoryOpened}"
          >
            <li
              v-for="(group, idx) in visibleCategories"
              :key="idx"
              class="product-details__categories-item"
            >
              <div class="product-details__categories-crumbs">
                <template v-for="(category, groupIdx) in group" >
                  <span :key="category.key + 'separator'" v-if="groupIdx > 0"> > </span>
                  <router-link
                    :key="category.key"
                    :to="category.url"
                    v-html="category.title"
                    class="product-details__categories-link"
                  ></router-link>
                </template>
              </div>
              <span
                v-if="!isMobile && allCategories.length > 1 && idx === 0"
                @click="isCategoryOpened = !isCategoryOpened"
                class="product-details__categories-toggle"
                :class="{ 'product-details__categories-toggle--active': isCategoryOpened }"
              >
                <span>{{ isCategoryOpened ? 'Show less' : 'Show more' }}</span>
                <i class="icon-angle-right"></i>
              </span>
            </li>
          </ul>
        </div>
        <div class="product__gallery">
          <div
            class="product__img--wrapper"
            :class="{'product__img--wrapper--video': productData.image.type === 'video' && !productVariationsActiveImage }"
          >
            <ImageZoom
              v-if="productData.image.type === 'image' || !!productVariationsActiveImage"
              class="product__img"
              :regular="activeMainProductImage || '/img-placeholder.svg'"
              :zoom="activeMainProductImage || '/img-placeholder.svg'"
              :zoom-amount="2"
              img-class="product__img--regular"
              :click-zoom="true"
              :show-message="false"
              :alt="productData.image.title"
            />
            <iframe
              v-else-if="productData.image.type === 'video'"
              class="product__img product__img--full-width product__img--video"
              width="100%"
              height="100%"
              :src="productData.image.src"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              loading="lazy"
            ></iframe>
            <object
              v-else-if="productData.image.type === 'asset3d'"
              class="product__img product__img--full-width product__img--asset"
              type="text/html"
              :data="productData.image.src"
            ></object>
          </div>
          <div class="product__image-subtext" v-if="productData.primaryImageStatus === 'R'">
            <span
              v-tooltip="'This image may only be a representation <br/> of the type of products within this category'"
            >* Representative image</span>
          </div>
          <ul class="product__thumbs-list" v-if="productData.images">
            <li
              class="product__thumbs-item"
              :class="{
                'product__thumbs-item--active': activeGalleryImageIdx === index
              }"
              v-for="(thumb, index) in productData.images"
              :key="thumb.src + index"
              @click="handleThumbClick(thumb, index)"
            >
              <div
                v-if="thumb.type === 'video'"
                class="product__thumbs-video"
                :class="{'product__thumbs-video--gray': !thumb.previewSrc}"
              >
                <img
                  v-if="thumb.previewSrc"
                  class="product__thumbs-img"
                  :src="thumb.previewSrc"
                  loading="lazy"
                />
                <i class="icon-youtube product__thumbs-video-icon"></i>
              </div>
              <img
                v-else
                class="product__thumbs-img"
                :src="thumb.previewSrc || '/img-placeholder.svg'"
                :alt="thumb.title"
                loading="lazy"
              />
            </li>
          </ul>
          <img v-if="!isMobile && productData.logoImage" class="product__logo" :src="productData.logoImage" alt="Product Logo" loading="lazy" />
        </div>
      </div>
      <div class="product__info">
        <div
          v-if="productData.discontinued"
          class="product__discontinued"
        >
          Discontinued
        </div>
        <h1 class="product__name" v-html="productData.title"></h1>
        <p class="product__price">
          <ProductPrice
            :productData="productData"
            :isPurchasable="isProductPurchasable"
            :showPriceLabels="true"
          />
        </p>
        <p v-if="productData.brand" class="product__brand">by {{ productData.brand }}</p>
        <p v-if="productData.collectionName" class="product__collection">
          Collection Name:
          <router-link
            :to="`/search??query=${encodeURIComponent(productData.collectionName)}`"
            class="link"
          >{{productData.collectionName}}
          </router-link>
        </p>
        <p class="product__number">Catalog ID: {{ productData.catalogId }}</p>
        <div class="product__links-block" v-if="productData.prop65Link || productData.energyGuideLink">
          <a
            class="product__links-item"
            v-if="productData.prop65Link"
            :href="productData.prop65Link"
            target="_blank"
          >
            <i class="icon-file-exclamation product__links-icon"></i>
            <span
              :href="productData.prop65Link"
              class="product__links-title"
            >View Prop 65 Notice</span>
          </a>
          <a
            class="product__links-item"
            v-if="productData.energyGuideLink"
            :href="productData.energyGuideLink"
            target="_blank"
          >
            <div class="product__links-icon product__links-icon--complex">
              <i class="icon-file product__links-icon-outer"></i>
              <i class="icon-leaf product__links-icon-inner"></i>
            </div>
            <span
              :href="productData.energyGuideLink"
              class="product__links-title"
            >View Energy Guide</span>
          </a>
        </div>
        <div class="product__desc-wrapper">
          <p class="product__desc" v-html="productData.descriptionLong"></p>
          <div v-if="isFeaturesVisible">
            <div
              v-if="productData.featuresList"
              v-html="productData.featuresList"
              class="product__features-list"
            ></div>
            <div
              v-if="productData.featuresSpecial"
              v-html="productData.featuresSpecial"
              class="product__desc"
            ></div>
          </div>
          <button
            v-if="isFeaturesButtonVisible"
            v-html="!isFeaturesVisible ? 'Read more': 'Read less'"
            @click="isFeaturesVisible = !isFeaturesVisible"
            type="button"
            class="button button--underlined"></button>

          <img v-if="isMobile && productData.logoImage" class="product__logo" :src="productData.logoImage" alt="Product Logo" loading="lazy" />
        </div>

        <div class="product__notice" v-if="productData.pdpNotices.length > 0">
          <p v-for="notice in productData.pdpNotices" :key="notice">
            {{ notice }}
          </p>
        </div>

        <template v-if="isProductPurchasable">
          <div class="product__desc" v-if="productData.minOrderQty > 1">
            Incremental Order Quantity: {{ productData.minOrderQty }}
            <i
              class="icon-question-circle product__moq-tooltip"
              v-tooltip="`This item has an incremental order quantity of ${productData.minOrderQty}; <br/> typing in a quantity value may result in rounding`"
            />
          </div>
          <div class="product__counter">
            <span class="product__counter-label">Quantity</span>
            <ProductQuantityInput
              :minOrderQty="productData.minOrderQty"
              v-model="addToCartQty"
            />
          </div>
          <button
            type="button"
            class="button button--primary button--cart"
            :disabled="!addToCartQty"
            @click="onAddToCartClick(productData, addToCartQty)"
          >
            <i class="icon-shopping-cart"></i>
            Add to Cart
          </button>
        </template>
        <AddToProductsList
          :productData="productData"
          :analyticsData="{referencePage: 'Direct PDP'}"
          class="product-details__add-to-products-list"
        />
        <FavoritesIcon :productCode="productData.sku" class="product__favorites" />
        <ul class="product__sub-sections" v-if="showInventoryCheck || relatedProductsFinishList">
          <!--
          <li class="product__sub-sections-item" v-if="productData.productVariants">
            <h3 class="text-h4 product__sub-sections-title">Product Variations</h3>
            <ul class="product__variations-list">
              <li
                class="product__variations-item"
                v-for="product in productData.productVariants"
                :key="product.sku"
                @mouseover="productVariationsActiveImage = product.image.src"
                @mouseleave="productVariationsActiveImage = ''"
              >
                <CompareCheckbox
                  :value="productsToCompare.some(el => el.productData.sku === product.sku)"
                  :checkboxField="{ label: product.sku }"
                  :activeState="productsToCompare.some(el => el.productData.sku === product.sku)"
                  :disabledState="!productsToCompare.some(el => el.productData.sku === product.sku) && productsToCompare.length === maxProductsToCompare"
                  size="small"
                  @input="toggleCompareMainProductWithSelected({ mainProduct: productData, secondaryProduct: product })"
                />
                <router-link :to="`/product/${product.sku}`" class="product__variations-link">
                  <img
                    class="product__variations-img"
                    :src="product.image.previewSrc || '/img-placeholder.svg'"
                    :alt="product.image.title"
                    loading="lazy"
                  />
                  <div class="product__variations-descr">
                    <span class="product__variations-title">{{ product.catalogId }}</span>
                  </div>
                </router-link>
              </li>
            </ul>
          </li>
          -->
          <li class="product__sub-sections-item" v-if="relatedProductsFinishList && relatedProductsFinishList.length > 0">
            <h3 class="text-h4 product__sub-sections-title">Product Variations</h3>
            <ul class="product__variations-list">
              <li
                class="product__variations-item"
                v-for="product in relatedProductsFinishList"
                :key="product.sku"
                @mouseover="productVariationsActiveImage = product.image.src"
                @mouseleave="productVariationsActiveImage = ''"
              >
                <CompareCheckbox
                  :value="productsToCompare.some(el => el.productData.sku === product.sku)"
                  :checkboxField="{ label: product.sku }"
                  :activeState="productsToCompare.some(el => el.productData.sku === product.sku)"
                  :disabledState="!productsToCompare.some(el => el.productData.sku === product.sku) && productsToCompare.length === maxProductsToCompare"
                  size="small"
                  @input="toggleCompareMainProductWithSelected({ mainProduct: productData, secondaryProduct: product })"
                />
                <router-link :to="`/product/${product.sku}`" class="product__variations-link">
                  <img
                    class="product__variations-img"
                    :src="product.image.previewSrc || '/img-placeholder.svg'"
                    :alt="product.image.title"
                    loading="lazy"
                  />
                  <div class="product__variations-descr">
                    <span class="product__variations-title">{{ product.catalogId }}</span>
                  </div>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="product__sub-sections-item" v-if="showInventoryCheck">
            <h3 class="text-h4 product__sub-sections-title">More Options</h3>
            <ul class="product__more-options-list">
              <li class="product__more-options-item">
                <i class="icon-forklift product__more-options-icon"></i>
                <div>
                  <h4 class="text-h4 product__more-options-title">Check Inventory</h4>
                  <button
                    class="button button--underlined product__more-options-button"
                    @click="showInventoryCheckModal = true"
                  >See our current availability</button>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
    <section
      class="product-details__tabs container"
      v-if="isProductDetailsTabs"
    >
      <StickyTabs>
        <template v-slot:nav>
          <div class="product-details__sticky-tabs-left">
            <a
              href="#product-details"
              v-if="productData.classifications"
            >Product Details</a>
            <a
              href="#resources-and-downloads"
              v-if="productData.resources"
            >Resources and Downloads</a>
            <a
              href="#compare-products"
              v-if="compareSectionData"
            >Compare Products</a>
            <a
              href="#asset3d"
              v-if="productData.asset3d"
            >3D Product View</a>
            <a
              href="#related-products"
              v-if="relatedProductsSimilarList && relatedProductsSimilarList.length > 0"
            >Related Products</a>
            <a href="#related-productsAI">Similar Products</a>
          </div>
        </template>
        <template v-slot:cta v-if="isProductPurchasable && !isMobile">
          <div class="product-details__sticky-tabs-right">
            <button
              type="button"
              class="button button--primary button--cart"
              :disabled="!addToCartQty"
              @click="onAddToCartClick(productData, addToCartQty)"
            >
              <i class="icon-shopping-cart"></i>
              Add to Cart
            </button>
          </div>
        </template>
        <template v-slot:main>
          <div
            id="product-details"
            v-if="productData.classifications"
            class="section-accordion"
            :class="{ 'is-section-open': isSectionOpen }"
            @click="toggleSectionAccordion"
          >
            <h2 class="product-details__tabs-title section-accordion-header">Product Details <span class="show-all button button--underlined">Show all</span></h2>
            <div class="section-accordion--body">
              <Accordion
                v-for="(item, idx) in productData.classifications"
                :key="item.title + idx"
                :expanded="true"
              >
                <template v-slot:header>
                  {{ item.title }}
                </template>
                <template v-slot:content>
                  <table
                    v-if="item.list && item.list.length"
                    class="table table--striped"
                  >
                    <tr
                      v-for="(nameValue, idx) in item.list"
                      :key="nameValue.name + nameValue.value + idx"
                    >
                      <td class="table--name">{{ nameValue.name }}</td>
                      <td v-html="nameValue.value"></td>
                    </tr>
                  </table>
                </template>
              </Accordion>
            </div>
          </div>
          <div
            id="resources-and-downloads"
            v-if="productData.resources"
          >
            <h2 class="product-details__tabs-title">Resources and Downloads</h2>
            <div class="resources">
              <div
                class="resources__group"
                v-for="(group, resourceType) in productData.resources"
                :key="resourceType"
              >
                <h3 v-if="group.title" class="text-h4 resources__item-title">{{ group.title }}</h3>
                <ul class="resources__items" v-if="group.items">
                  <li
                    class="resources__item"
                    v-if="group.items.length > 1 && resourceType !== 'OTHER'"
                  >
                    <a
                      class="resources__item-link"
                      href="download"
                      @click.prevent="handleDownloadAllResourcesClick(resourceType)"
                    >
                      <i
                        class="resources__item-icon"
                        :class="[getResourceIcon('zip')]"
                      />
                      <span>Download All</span>
                    </a>
                  </li>
                  <li
                    class="resources__item"
                    v-for="item in group.items"
                    :key="item.link"
                  >
                    <a
                      class="resources__item-link"
                      :href="item.link"
                      target="_blank"
                    >
                      <i
                        class="resources__item-icon"
                        :class="[getResourceIcon(item.extension)]"
                      />
                      <span>{{ item.title }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            id="compare-products"
            v-if="compareSectionData"
          >
            <h2 class="product-details__tabs-title">Compare Similar Products</h2>
            <ProductsCompareSection :products="compareSectionData" />
          </div>
          <div
            id="asset3d"
            class="product-details__related-products"
            v-if="productData.asset3d"
          >
            <h2 class="product-details__tabs-title">3D Product View</h2>
            <object
              class="product__asset3d-preview"
              type="text/html"
              :data="productData.asset3d"
            ></object>
          </div>
          <div class="emptybox-loader" v-if="isLoadingRelatedProducts">
            <LoaderForRelatedProducts />
          </div>
          <div
            id="related-products"
            class="product-details__related-products"
            v-if="relatedProductsSimilarList && relatedProductsSimilarList.length > 0"
          >
            <h2 class="product-details__tabs-title">Related Products
              <i class="icon-question-circle"
                v-tooltip="`Complementary products selected by Hubbell experts`"
              />
            </h2>
            <ProductCarousel
              :productsList="relatedProductsSimilarList"
              :carouselClass="productCarouselOptions.className"
              :itemsPerPage="[[0, 1], [768, 3], [1024, 4], [1700, 5]]"
              :scrollPerPage="true"
              :navigationEnabled="productCarouselOptions.navigationEnabled"
              :paginationEnabled="productCarouselOptions.paginationEnabled"
              :isPriceVisible="true"
              :analyticsData="{referencePage: 'Related Products PDP', enableImpressions: true}"
              :isComparable="true"
              :compareMainProduct="productData"
            />
          </div>
          <div ref="related-products-ai" id="related-productsAI">
            <div class="emptybox-loader" v-if="isLoadingRelatedProductsAI">
              <LoaderForRelatedProducts />
            </div>
            <div
              class="product-details__related-products"
              v-if="relatedProductsListAlgolia && relatedProductsListAlgolia.length > 0"
            >
              <h2 class="product-details__tabs-title">Similar Products
                <i class="icon-question-circle"
                  v-tooltip="`Automatically suggested by Hubbell AI`"
                />
              </h2>
              <ProductCarousel
                :productsList="relatedProductsListAlgolia"
                :carouselClass="productCarouselOptions.className"
                :itemsPerPage="[[0, 1], [768, 3], [1024, 4], [1700, 5]]"
                :scrollPerPage="true"
                :navigationEnabled="productCarouselOptions.navigationEnabled"
                :paginationEnabled="productCarouselOptions.paginationEnabled"
                :isPriceVisible="true"
                :analyticsData="{referencePage: 'Related Products Algolia AI', enableImpressions: true}"
                :isComparable="true"
                :compareMainProduct="productData"
              />
            </div>
          </div>
        </template>
      </StickyTabs>
    </section>
    <section
      v-if="productData.content && productData.content.bannerA"
      class="product-details__content"
    >
      <Banner :data="productData.content.bannerA" :parentKey="productData.catalogId" />
    </section>

    <div
      v-if="isProductPurchasable && isMobile"
      class="product-details__mobile-add-to-cart-overlay"
    >
      <div class="product-details__mobile-add-to-cart-overlay-left">
        <div class="product__counter">
          <span class="product__counter-label">Quantity</span>
          <ProductQuantityInput
            :minOrderQty="productData.minOrderQty"
            :isButtonsVisible="false"
            v-model="addToCartQty"
          />
        </div>
        <div class="product__desc" v-if="productData.minOrderQty > 1">
          Incremental Order Quantity: {{ productData.minOrderQty }}
          <i
            class="icon-question-circle product__moq-tooltip"
            v-tooltip="`This item has an incremental order quantity of ${productData.minOrderQty}; <br/> typing in a quantity value may result in rounding`"
          />
        </div>
      </div>
      <button
        type="button"
        class="button button--primary button--cart"
        :disabled="!addToCartQty"
        @click="onAddToCartClick(productData, addToCartQty)"
      >
        <i class="icon-shopping-cart"></i>
        Add to Cart
      </button>
    </div>

    <ProductsCompareOverlay v-if="relatedProductsSimilarList || relatedProductsFinishList || relatedProductsListAlgolia" />

    <ModalCart
      v-if="showModalCart"
      @close="closeCartModal"
      @addProduct="onAddToCartFromModalCart"
      :isLoading="isModalCartLoading"
      :productEntry="productEntryData"
      :showModalCart="showModalCart"
    />

    <ModalInventory
      :inventoryData="inventoryData"
      :showModal="showModalInventory"
      @close="closeInventoryModal"
    />

    <ModalDownloadResources
      v-if="showDownloadAllResourcesByTypeLinkModal"
      :productCode="productData.sku"
      :resourceType="resourceType"
      :showModal="showDownloadAllResourcesByTypeLinkModal"
      @close="closeDownloadAllResourcesByTypeLinkModal"
    />

    <ModalInventoryCheck
      v-if="showInventoryCheckModal"
      :showModal="showInventoryCheckModal"
      :productData="{ code: productData.sku, title: productData.title }"
      @close="showInventoryCheckModal = false"
    />

    <AddThis publicId="ra-5a25b6ec45619412" />

    <loader v-if="isLoading" />
  </div>
  <ErrorOverlay v-else-if="hasErrorOverlay">
    <h2 slot="body" class="text-h2">Cannot currently open this product, please contact <router-link to="/customer-support" class="text-underline">support</router-link></h2>
  </ErrorOverlay>
  <loader v-else/>
</template>

<script>
import Masonry from 'masonry-layout'
import ImageZoom from 'vue-image-zoomer'
import AddThis from 'vue-simple-addthis-share'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

import AnalyticsService from '@/services/analytics'
import settings from '@/settings'
import resourcesIconsMap from '@/data/resources-icons-map'
import { parseEncodedString, detectUserAgentBot } from '@/helpers'
import AddToCartMixin from '@/mixins/AddToCartMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import PageMixin from '@/mixins/PageMixin'
import ProductsCompareOverlayMixin from '@/mixins/ProductsCompareOverlayMixin'
import IntersectionObserverMixin from '@/mixins/IntersectionObserverMixin'
import Accordion from '@/components/Accordion'
import Banner from '@/components/Banner'
import ModalCart from '@/components/modal/ModalCart'
import ModalInventory from '@/components/modal/ModalInventory'
import ModalDownloadResources from '@/components/modal/ModalDownloadResources'
import ModalInventoryCheck from '@/components/modal/ModalInventoryCheck'
import ProductPrice from '@/components/ProductPrice'
import ProductQuantityInput from '@/components/ProductQuantityInput'
import ErrorOverlay from '@/components/ErrorOverlay'
import FavoritesIcon from '@/components/FavoritesIcon'
import StickyTabs from '@/components/StickyTabs'
import ProductCarousel from '@/components/ProductCarousel'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import HintBar from '@/components/HintBar'
import ProductsCompareOverlay from '@/components/ProductsCompareOverlay'
import CompareCheckbox from '@/components/CompareCheckbox'
import AddToProductsList from '@/components/AddToProductsList'
import ProductsCompareSection from '@/components/ProductsCompareSection'
import LoaderForRelatedProducts from '@/components/LoaderForRelatedProducts'

export default {
  name: 'ProductDetails',
  components: {
    AddThis,
    Accordion,
    Banner,
    ModalCart,
    ModalInventory,
    ModalDownloadResources,
    ModalInventoryCheck,
    ProductPrice,
    ProductQuantityInput,
    ErrorOverlay,
    FavoritesIcon,
    StickyTabs,
    ProductCarousel,
    ImageZoom,
    HintBar,
    ProductsCompareOverlay,
    CompareCheckbox,
    AddToProductsList,
    ProductsCompareSection,
    LoaderForRelatedProducts
  },
  mixins: [
    AddToCartMixin,
    LoaderMixin,
    PageMixin,
    ScreenWidthMixin,
    ProductsCompareOverlayMixin,
    IntersectionObserverMixin
  ],
  watch: {
    isProductDetailsTabs (value) {
      if (value) {
        this.$nextTick(() => {
          this.observeElement(this.$refs['related-products-ai'])
        })
      }
    },
    isIntersecting (value) {
      if (value && !this.relatedProductsListAlgolia && !detectUserAgentBot()) {
        this.getRelatedProductsAI()
      }
    }
  },
  computed: {
    ...mapState('product', ['productData', 'productAnalyticsData']),
    ...mapState('search', ['previousSearchPagePath']),
    ...mapGetters('user', ['isGuest', 'isPublic', 'isEmployee', 'isAffiliate']),
    isProductPurchasableForCurrentUserType () {
      if (this.isPublic && this.productData.isPublicStore) return true
      if (this.isEmployee && this.productData.isEmployeeStore) return true
      if (this.isAffiliate && this.productData.isAffiliateStore) return true
      return false
    },
    isProductPurchasable () {
      return this.productData.hasPrice && this.productData.isPurchasable && this.isProductPurchasableForCurrentUserType
    },
    isFeaturesButtonVisible () {
      return (this.productData.featuresList || this.productData.featuresSpecial) && this.isFeaturesCollapsed
    },
    isFeaturesCollapsed () {
      const featuresListLength = this.productData.featuresList ? this.productData.featuresList.length : 0
      const featuresSpecialLength = this.productData.featuresSpecial ? this.productData.featuresSpecial.length : 0
      const descLength = this.productData.descriptionLong.length ? this.productData.descriptionLong.length : 0
      return (featuresListLength + featuresSpecialLength + descLength) > 400
    },
    productCarouselOptions () {
      return this.isMobile
        ? {
          className: 'carousel-arrow-navigation',
          navigationEnabled: true,
          paginationEnabled: false
        }
        : {
          className: 'carousel-arrow-navigation',
          navigationEnabled: true,
          paginationEnabled: true
        }
    },
    showInventoryCheck () {
      return this.productData.showInventory && this.isProductPurchasable
    },
    activeMainProductImage () {
      return this.productVariationsActiveImage || this.productData.image.src
    },
    visibleCategories () {
      return this.isCategoryOpened && !this.isMobile ? this.allCategories : this.allCategories.slice(0, 1)
    },
    metaData () {
      return {
        description: this.productData?.descriptionShort
      }
    },
    compareSectionData () {
      return this.productData.comparableProducts?.length ? [this.productData, ...this.productData.comparableProducts] : null
    },
    isProductDetailsTabs () {
      return !!(this.productData?.classifications || this.productData?.resources || this.productData?.productReferences)
    }
  },
  methods: {
    ...mapActions('product', ['setProductData', 'unsetProductData', 'unsetStructureDataMarkupScript']),
    ...mapActions('product', ['getAllRelatedProducts']),
    ...mapActions('product', ['getRelatedProductsFromAlgolia']),
    ...mapMutations('product', {
      unsetProductData: 'UNSET_PRODUCT_DATA',
      setProductAnalyticsData: 'SET_PRODUCT_ANALYTICS_DATA'
    }),
    ...mapMutations('search', {
      setScrollPosition: 'SET_SCROLL_POSITION'
    }),
    onAddToCartClick (productData, addToCartQty) {
      this.isLoading = true
      this.addProductToCart({
        productData,
        addToCartQty,
        position: this.productAnalyticsData?.indexName && this.productAnalyticsData?.queryID
          ? this.productAnalyticsData?.position
          : 0,
        pageName: this.productAnalyticsData?.indexName && this.productAnalyticsData?.queryID
          ? this.productAnalyticsData?.referencePage || 'Search Results'
          : this.productAnalyticsData?.referencePage || 'Direct PDP',
        searchQuery: this.productAnalyticsData?.indexName && this.productAnalyticsData?.queryID
          ? this.productAnalyticsData?.searchQuery
          : '',
        indexName: this.productAnalyticsData?.indexName,
        queryID: this.productAnalyticsData?.queryID
      }).catch(error => {
        if (error?.response?.data?.length) {
          if (error.response.data.some(el => el.type === 'CommerceCartModificationError')) {
            this.showHintUnavailableProduct = true
          }
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    onAddToCartFromModalCart ({ productData, addToCartQty }) {
      this.isModalCartLoading = true
      return this.addProductToCart({
        productData,
        addToCartQty,
        position: 0,
        pageName: 'Related Products Add to Cart',
        searchQuery: ''
      }).finally(() => {
        this.isModalCartLoading = false
      })
    },
    handleThumbClick (thumb, index) {
      this.productData.image = thumb
      this.activeGalleryImageIdx = index
    },
    getResourceIcon (linkType) {
      return resourcesIconsMap[linkType] || resourcesIconsMap.default
    },
    handleDownloadAllResourcesClick (resourceType) {
      this.resourceType = resourceType
      this.showDownloadAllResourcesByTypeLinkModal = true
    },
    closeDownloadAllResourcesByTypeLinkModal () {
      this.showDownloadAllResourcesByTypeLinkModal = false
      this.recourcesType = null
    },
    prepareCategoriesCrumbs () {
      if (!this.productData.groupedCategories?.length) return
      const homeCategory = {
        id: 'home',
        title: 'Home',
        url: '/'
      }
      const productCategories = this.productData.groupedCategories.map(group => {
        return group.map(({ key, url, title, originalTitle }) => ({ key, url, title, originalTitle }))
      })
      const sortingOptions = {
        sortByCatalog: (a, b) => {
          const valA = a.map(el => el.originalTitle).includes(this.productAnalyticsData.catalogOriginalTitle)
          const valB = b.map(el => el.originalTitle).includes(this.productAnalyticsData.catalogOriginalTitle)
          return valB - valA
        },
        sortByAlphabet: (a, b) => {
          const joinedTitlesA = a.map(el => el.title).join('')
          const joinedTitlesB = b.map(el => el.title).join('')
          return joinedTitlesA.localeCompare(joinedTitlesB)
        }
      }

      productCategories.sort(sortingOptions.sortByAlphabet)
      if (this.productAnalyticsData?.catalogOriginalTitle) productCategories.sort(sortingOptions.sortByCatalog)

      productCategories[0].unshift(homeCategory)
      this.allCategories = productCategories
    },
    toggleSectionAccordion () {
      this.isSectionOpen = !this.isSectionOpen
    },
    async getRelatedProductsAI () {
      this.isLoadingRelatedProductsAI = true
      this.relatedProductsListAlgolia = await this.getRelatedProductsFromAlgolia({
        productCode: this.productData.sku,
        quantity: null
      }).finally(() => {
        this.isLoadingRelatedProductsAI = false
      })
    }
  },
  async created () {
    this.unsetProductData()

    if (this.$route.query[settings.app.queryTokens.forceLogin]) {
      const query = Object.assign({}, this.$route.query)
      delete query[settings.app.queryTokens.forceLogin]
      this.$router.replace({ query })
    }
    const productId = this.$route.params.id
    try {
      await this.withErrorOverlay(() => this.setProductData(productId))
      const pageTitle = `${parseEncodedString(this.productData?.title)} | ${this.$route.meta?.title}`
      this.setPageTitle(pageTitle)
      this.setMetaTags()
      if (this.productData?.resources) {
        this.masonryGrid = new Masonry('.resources', {
          itemSelector: '.resources__group',
          percentPosition: true,
          gutter: 30
        })
      }

      this.addToCartQty = this.productData?.minOrderQty
      if (this.productData && !this.productData?.image?.src) this.handleThumbClick(this.productData.images[0], 0)

      this.prepareCategoriesCrumbs()

      if (settings.services.analytics.hubspotScript.enabled) AnalyticsService.trackHubspotPageView(this.$route.path)

      document.querySelector('body').classList.add('show-addthis')
      if (window.addthis_share) {
        window.addthis_share.title = pageTitle
        window.addthis_share.url = window.location.href
      }
    } catch {
      if (this.hasErrorOverlay && this.$route.query[settings.app.queryTokens.switchSite]) this.$router.push('/')
    }
    this.isLoadingRelatedProducts = true
    this.relatedProductsFullList = await this.getAllRelatedProducts({
      productCode: productId,
      quantity: null
    }).finally(() => {
      this.isLoadingRelatedProducts = false
    })
    this.relatedProductsSimilarList = this.relatedProductsFullList.relatedProducts
    this.relatedProductsFinishList = this.relatedProductsFullList.finishColorEntries
  },
  beforeRouteLeave (to, from, next) {
    if (to.path !== this.previousSearchPagePath) this.setScrollPosition(0)
    next()
  },
  beforeDestroy () {
    this.setProductAnalyticsData(null)
    this.unsetMetaTags()
    if (this.isGuest) this.unsetStructureDataMarkupScript()

    document.querySelector('body').classList.remove('show-addthis')
  },
  data () {
    return {
      addToCartQty: 1,
      activeGalleryImageIdx: 0,
      isFeaturesVisible: true,
      showDownloadAllResourcesByTypeLinkModal: false,
      resourceType: null,
      showInventoryCheckModal: false,
      showHintUnavailableProduct: false,
      productVariationsActiveImage: '',
      isCategoryOpened: false,
      allCategories: [],
      isModalCartLoading: false,
      masonryGrid: null,
      relatedProductsListAlgolia: null,
      relatedProductsSimilarList: null,
      relatedProductsFinishList: null,
      relatedProductsFullList: null,
      isLoadingRelatedProducts: false,
      isLoadingRelatedProductsAI: false,
      isSectionOpen: true
    }
  }
}
</script>
